<template>
    <div class="preview_area show_sidebar" v-if="modelValue" :class="{active : modelValue}">
        <div class="global_setting">
            <div class="tabs_content">
                <button class="close_btn" @click="closeModal()"><i class="fas fa-long-arrow-alt-left"></i></button>
                <div class="stat_container">
                    <div class="report_card">
                        <div class="header">
                            <div class="user_info">
                                <img :src="user.profile_pic" alt="">
                                <div>
                                    <h4>{{ user.full_name }}</h4>
                                    <h3>Started Tracking &nbsp;<span>&#123;&#123;Oct 21, 2022&#125;&#125;</span></h3>
                                </div>
                            </div>
                            <div class="submission">
                                <h4>&#123;&#123;1/115&#125;&#125;</h4>
                                <h3>Submissions</h3>
                            </div>
                        </div>
                    </div>
                    <div class="section_header">
                        <h2>Habits</h2>
                        <!-- <div>
                            <button type="button" class="pointer" @click="calendarView = ! calendarView">{{ calendarView ? 'List View' : 'Calendar View' }}</button>
                        </div> -->
                    </div>
                    <ul class="tracking_list">
                        <li>
                            <h4>Participation Score: <span>20%</span></h4>
                            <div class="score_circle" style="background: #efefef; width:40px; height:40px; padding: 3px;">
                                <div class="inner_circle">
                                    <div class="score" style="transform: scale(0.8);">20<span>%</span></div>
                                </div>
                                <svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="40" height="40">
                                    <circle cx="20" cy="20" r="18.5" stroke-linecap="round" fill="none" stroke-width="3px" :stroke="'#1976d2'" :stroke-dasharray="113" :stroke-dashoffset="20"/>
                                </svg>
                            </div>
                        </li>
                        <li>
                            <h4>Habit Score: <span>10%</span></h4>
                            <div class="score_circle" style="background: #efefef; width:40px; height:40px; padding: 3px;">
                                <div class="inner_circle">
                                    <div class="score" style="transform: scale(0.8);">10<span>%</span></div>
                                </div>
                                <svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="40" height="40">
                                    <circle cx="20" cy="20" r="18.5" stroke-linecap="round" fill="none" stroke-width="3px" :stroke="'#1976d2'" :stroke-dasharray="113" :stroke-dashoffset="10"/>
                                </svg>
                            </div>
                        </li>
                        <li>
                            <h4>Best Streak: <span>0 <small>Day</small></span></h4>
                            <div class="score_circle" style="background: #efefef; width:40px; height:40px; padding: 3px;">
                                <div class="inner_circle">
                                    <div class="score" style="transform: scale(0.8);">0 <span>D</span></div>
                                </div>
                                <svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="40" height="40">
                                    <circle cx="20" cy="20" r="18.5" stroke-linecap="round" fill="none" stroke-width="3px" :stroke="'#1976d2'" :stroke-dasharray="113" :stroke-dashoffset="0"/>
                                </svg>
                            </div>
                        </li>
                    </ul>
                    <div class="habit_calendar" v-show="calendarView">
                        <v-calendar :first-day-of-week="2" :attributes="attributes" @dayclick="handleHabitsClick" is-expanded>
                            <!-- <template #day-popover="{ dayTitle }">
                                <div class="task_details">
                                    <div class="task_header">
                                        {{ dayTitle }}
                                    </div>
                                    <div class="quest_panel">
                                        <template v-for="(category, index) in categories" :key="index">
                                            <h3 class="category_title">{{ category.name }}</h3>
                                            <div class="category_wpr">
                                                <div class="task_info" v-for="(item, i) of category.habits" :key="i">
                                                    <h4><label>{{ i + 1 }}</label>{{ item.description }}</h4>
                                                    <div class="details">
                                                        <p>{{ item.requirements ? item.requirements : 'No requirement available'}}</p>
                                                        <div class="perform_activity" v-if="item.type == 1">
                                                            <div class="activity_wpr_center">
                                                                <span>Completed?</span>
                                                                <div class="grp_wpr">
                                                                    <label class="yes">
                                                                        <div class="box_item"><i class="fas fa-thumbs-up mr-2"></i>Yes</div>
                                                                    </label>
                                                                    <label class="no">
                                                                        <div class="box_item"><i class="fas fa-thumbs-down mr-2"></i>No</div>
                                                                    </label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="perform_activity" v-if="item.type == 2">
                                                            <div class="success_goal"><i class="fas fa-trophy"></i>{{ `Goal is ${ item.success_goal > 1 ? item.success_goal+' days' : item.success_goal+' day' }` }} per week</div>
                                                            <div class="days_wpr">
                                                                <span>Days this week I was compliant!</span>
                                                                <ul class="days">
                                                                    <li v-for="(week, index) in weekdays" :key="index">
                                                                        <label class="active">
                                                                            <div class="item_wpr">
                                                                                <span class="weekdays">{{ week.title.slice(0, 3) }}</span>
                                                                                <span class="tick_box active"></span>
                                                                            </div>
                                                                        </label>
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                        <div class="upload_image" v-show="item.submit_proof">
                                                            <image-upload image-type="all" upload-text="Image"  :is-avatar="false" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </template>
                                    </div>
                                </div>
                            </template> -->
                        </v-calendar>
                        <div class="streaks">
                            <h4>Streaks</h4>
                            <ul>
                                <li><i class="fas fa-trophy"></i></li>
                                <li><i class="fas fa-trophy"></i></li>
                                <li><i class="fas fa-trophy"></i></li>
                                <li><i class="fas fa-trophy"></i></li>
                                <li><i class="fas fa-trophy"></i></li>
                            </ul>
                        </div>
                        <div class="instruction">Click today to view a sample submission <img src="@/assets/images/curve_dash_arrow.svg" alt=""></div>
                    </div>
                    <ul class="habit_list px-0" v-show="!calendarView">
                        <li v-for="(category, index) of categories" :key="index">
                            <div class="category_title"><h2>{{ category.name }}</h2></div>
                            <div class="habit_wpr" v-for="(item, i) of category.habits" :key="i">
                                <div class="title">
                                    {{ item.description ? item.description : '&nbsp;' }}
                                    <div class="right">
                                        <div class="dropdown" @click="requirementToggle = requirementToggle == item.id ? 0 : item.id">
                                            <i class="fas fa-info-circle"></i>
                                            <div class="drp_wrapper" v-if="requirementToggle == item.id" :class="{ active : requirementToggle == item.id }">
                                                <div class="quick_info">
                                                    <div class="info_title">Requirement</div>
                                                    <p>{{ item.requirements }}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="habit_info">
                                    <ul>
                                        <li>
                                            <div class="score_circle" style="width:40px; height:40px; padding: 3px;">
                                                <div class="inner_circle">
                                                    <div class="score" style="transform: scale(0.8);">0<span>%</span></div>
                                                </div>
                                                <svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="40" height="40">
                                                    <circle cx="20" cy="20" r="18.5" stroke-linecap="round" fill="none" stroke-width="3px" :stroke="'#1976d2'" :stroke-dasharray="113" :stroke-dashoffset="100"/>
                                                </svg>
                                            </div>
                                            <h6>Habit Score</h6>
                                        </li>
                                        <li>
                                            <div class="score_circle" style="width:40px; height:40px; padding: 3px;">
                                                <div class="inner_circle">
                                                    <div class="score" style="transform: scale(0.8);">0<span>D</span></div>
                                                </div>
                                                <svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="40" height="40">
                                                    <circle cx="20" cy="20" r="18.5" stroke-linecap="round" fill="none" stroke-width="3px" :stroke="'#1976d2'" :stroke-dasharray="113" :stroke-dashoffset="100"/>
                                                </svg>
                                            </div>
                                            <h6>Best Streak</h6>
                                        </li>
                                    </ul>
                                </div>
                                <div class="habit_footer">
                                    <ul class="day_sorting" v-if="habitScores" @mousewheel="scrollFunc($event)">
                                        <template v-for="(answer, week) of habitScores" :key="week">
                                            <li @click="handleWeekDialog(week + 1)">
                                                <i :class="`far fa-square`"></i>Day {{ parseInt(week) + 1 }}
                                            </li>
                                        </template>
                                    </ul>
                                </div>
                            </div>
                        </li>
                    </ul>
                    <div class="section_header pt-4">
                        <h2>History</h2>
                        <div class="instruction">View submission history <img src="@/assets/images/curve_dash_arrow.svg" alt=""></div>
                    </div>
                    <div class="result_wpr">
                        <div class="history_tbl">
                            <table>
                                <thead>
                                    <tr>
                                        <th>Date</th>
                                        <th>Week of Scenario</th>
                                        <th>Status</th>
                                        <th>Entry</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>Aug 25, 2023</td>
                                        <td>Week 45</td>
                                        <td>
                                            <span>Edited After Submission</span>
                                        </td>
                                        <td>
                                            <button @click="historyDrawer = true;"><i class="fas fa-search-plus"></i></button>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Aug 18, 2023</td>
                                        <td>Week 44</td>
                                        <td>
                                            <span>Missed</span>
                                        </td>
                                        <td>
                                            <button @click="historyDrawer = true;"><i class="fas fa-search-plus"></i></button>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Aug 14, 2023</td>
                                        <td>Week 43</td>
                                        <td>
                                            <span>Edited After Submission</span>
                                        </td>
                                        <td>
                                            <button @click="historyDrawer = true;"><i class="fas fa-search-plus"></i></button>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal secondary details_dialog" v-if="WeekDialog">
            <div class="modal_container">
                <div class="modal_header border-bottom">
                    <h1 class="m-0">Day {{ weekSr }}</h1>
                    <button class="close_btn" @click="WeekDialog = false;"><i class="fas fa-times"></i></button>
                </div>
                <div class="modal_body">
                    <div class="task_details">
                        <template v-for="(category, index) in categories" :key="index">
                            <h3 class="category_title">{{ category.name }}</h3>
                            <div class="category_wpr">
                                <div class="task_info" v-for="(item, i) of category.habits" :key="i">
                                    <h4><label>{{ i + 1 }}</label>{{ item.description }}</h4>
                                    <div class="details">
                                        <p>{{ item.requirements ? item.requirements : 'No requirement available'}}</p>
                                        <div class="perform_activity" v-if="item.type == 1">
                                            <div class="activity_wpr_center">
                                                <span>Completed?</span>
                                                <div class="grp_wpr">
                                                    <label class="yes">
                                                        <div class="box_item"><i class="fas fa-thumbs-up mr-2"></i>Yes</div>
                                                    </label>
                                                    <label class="no">
                                                        <div class="box_item"><i class="fas fa-thumbs-down mr-2"></i>No</div>
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="perform_activity" v-if="item.type == 2">
                                            <div class="success_goal"><i class="fas fa-trophy"></i>{{ `Goal is ${ item.success_goal > 1 ? item.success_goal+' days' : item.success_goal+' day' }` }} per week</div>
                                            <div class="days_wpr">
                                                <span>Days this week I was compliant!</span>
                                                <ul class="days">
                                                    <li v-for="(week, index) in weekdays" :key="index">
                                                        <label class="active">
                                                            <div class="item_wpr">
                                                                <span class="weekdays">{{ week.title.slice(0, 3) }}</span>
                                                                <span class="tick_box active"></span>
                                                            </div>
                                                        </label>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div class="upload_image" v-show="item.submit_proof">
                                            <image-upload image-type="all" upload-text="Image"  :is-avatar="false" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </template>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal habit_drawer" v-if="dayDrawer">
            <div class="modal_container">
                <div class="modal_header border-bottom">
                    <div class="modal_info">
                        <h1>Habits <span>{{ selectedDate }}</span></h1>
                        <button class="close_btn left_out" @click="dayDrawer = false;"><i class="fas fa-long-arrow-alt-right"></i></button>
                    </div>
                </div>
                <div class="modal_body">
                    <div class="task_details">
                        <template v-for="(category, index) in categories" :key="index">
                            <h3 class="category_title">{{ category.name }}</h3>
                            <div class="category_wpr">
                                <div class="task_info" v-for="(item, i) of category.habits" :key="i">
                                    <div class="sample_type">{{item.type == 1 ? 'This is a daily habit submission' : 'This is a weekly habit submission'}}</div>
                                    <h4><label>{{ i + 1 }}</label>{{ item.description }}</h4>
                                    <div class="details">
                                        <p>{{ item.requirements ? item.requirements : 'No requirement available'}}</p>
                                        <div class="perform_activity" v-if="item.type == 1">
                                            <div class="activity_wpr">
                                                <!-- <span>Completed?</span> -->
                                                <div class="grp_wpr">
                                                    <label class="yes">
                                                        <div class="box_item"><i class="fas fa-thumbs-up"></i>Yes</div>
                                                    </label>
                                                    <label class="no">
                                                        <div class="box_item"><i class="fas fa-thumbs-down"></i>No</div>
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="perform_activity" v-if="item.type == 2">
                                            <div class="success_goal"><i class="fas fa-trophy"></i>{{ `Goal is ${ item.success_goal > 1 ? item.success_goal+' days' : item.success_goal+' day' }` }} per week</div>
                                            <div class="days_wpr">
                                                <span>Days this week I was compliant!</span>
                                                <ul class="days">
                                                    <li v-for="(week, index) in weekdays" :key="index">
                                                        <label class="active">
                                                            <div class="item_wpr">
                                                                <span class="weekdays">{{ week.title.slice(0, 3) }}</span>
                                                                <span class="tick_box active"></span>
                                                            </div>
                                                        </label>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                        <!-- <div class="upload_image" v-show="item.submit_proof">
                                            <image-upload image-type="all" upload-text="Image"  :is-avatar="false" />
                                        </div> -->
                                    </div>
                                </div>
                            </div>
                        </template>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal habit_drawer" v-if="historyDrawer">
            <div class="modal_container">
                <div class="modal_header border-bottom">
                    <div class="modal_info">
                        <h1>Habit Details <span>Friday, 25 August 2023</span></h1>
                        <button class="close_btn left_out" @click="historyDrawer = false;"><i class="fas fa-long-arrow-alt-right"></i></button>
                    </div>
                </div>
                <div class="modal_body">
                    <div class="task_details">
                        <template v-for="(history, h) in submissionHistory" :key="h">
                            <h3 class="category_title">{{ history.name }}</h3>
                            <div class="category_wpr">
                                <div class="task_info" v-for="(item, i) of history.habits" :key="i">
                                    <h4><label>{{ i + 1 }}</label>{{ item.description }}</h4>
                                    <div class="details">
                                        <p>{{ item.requirements ? item.requirements : 'No requirement available'}}</p>
                                        <div class="perform_activity" v-if="item.type == 1">
                                            <div class="activity_wpr">
                                                <!-- <span>Completed?</span> -->
                                                <div class="grp_wpr">
                                                    <label class="yes" v-if="item.answer">
                                                        <div class="box_item"><i class="fas fa-thumbs-up"></i>Yes</div>
                                                    </label>
                                                    <label class="no" v-else>
                                                        <div class="box_item"><i class="fas fa-thumbs-down"></i>No</div>
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="perform_activity" v-if="item.type == 2">
                                            <div class="success_goal"><i class="fas fa-trophy"></i>{{ `Goal is ${ item.success_goal > 1 ? item.success_goal+' days' : item.success_goal+' day' }` }} per week</div>
                                            <div class="days_wpr">
                                                <span>Days this week I was compliant!</span>
                                                <ul class="days">
                                                    <li v-for="(week, w) in weekdays" :key="w">
                                                        <label class="active">
                                                            <div class="item_wpr">
                                                                <span class="weekdays">{{ week.title.slice(0, 3) }}</span>
                                                                <span class="tick_box active"></span>
                                                            </div>
                                                        </label>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </template>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { defineAsyncComponent } from 'vue'
import { VueEcharts } from 'vue3-echarts'
import { mapState } from 'vuex'

const ImageUpload = defineAsyncComponent(() => import('@/components/image-library/ImageUpload'))

export default {
    name: 'Workouts Stats Details',

    data () {
        return{
            searchField: false,
                calendarView: true,
                WeekDialog: false,
                dayDrawer: false,
                historyDrawer: false,
                selectedDate: '',
                weekSr: 0,
                requirementToggle: 0,
                attributes: [
                    {
                        key: 1,
                        dates: new Date(),
                        highlight: {
                            class: false,
                            fillMode: 'solid',
                            contentClass: 'assign_day',
                        },
                        // popover: {
                        //     visibility: 'click',
                        // },
                    },
                ],
                categories: [
                    {
                        name:"Sleep",
                        habits:[
                            {
                                id: 1,
                                type: 1,
                                name:"Sleep",
                                description:"Did you sleep at least 7 hours last night?",
                                requirements:"Aim for 7+ hours of sleep per night to optimize health.",
                                success_goal:7,
                                submit_proof:1,
                            },
                            {
                                id: 2,
                                type: 2,
                                name:"Sleep",
                                description:"Did you sleep at least 7 hours last night?",
                                requirements:"Aim for 7+ hours of sleep per night to optimize health.",
                                success_goal:7,
                                submit_proof:1,
                            }
                        ]
                    },
                ],
                submissionHistory: [
                    {
                        name:"Sleep",
                        habits:[
                            {
                                id: 1,
                                type: 1,
                                name:"Sleep",
                                description:"Did you sleep at least 7 hours last night?",
                                requirements:"Aim for 7+ hours of sleep per night to optimize health.",
                                success_goal:7,
                                answer:1,
                            },
                        ]
                    },
                    {
                        name:"Hydration",
                        habits:[
                            {
                                id: 1,
                                type: 1,
                                name:"Hydration",
                                description:"Did you drink at least a gallon of water today?",
                                requirements:"Aim for 1 gallon of water per day to optimize health",
                                success_goal:7,
                                answer:1,
                            },
                        ]
                    },
                    {
                        name:"Vitamins",
                        habits:[
                            {
                                id: 1,
                                type: 1,
                                name:"Vitamins",
                                description:"Did you take your vitamins today?",
                                requirements:"Aim for 1 dose of quality multivitamins per day to optimize health.",
                                success_goal:7,
                                answer:0,
                            },
                        ]
                    },
                ],
                weekdays: [
                    { title: 'Monday', label: 'M', value: 1 },
                    { title: 'Tuesday', label: 'T', value: 2 },
                    { title: 'Wednesday', label: 'W', value: 3 },
                    { title: 'Thursday', label: 'T', value: 4 },
                    { title: 'Friday', label: 'F', value: 5 },
                    { title: 'Saturday', label: 'S', value: 6 },
                    { title: 'Sunday', label: 'S', value: 0 },
                ],
                logo: [],
                habitScores: [ "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "" ],
        }
    },

    components:{
        ImageUpload,
    },

    props: {
        modelValue: Boolean,
    },

    emit: ['update:modelValue'],

    computed: {
        ...mapState({
            user: state => state.authModule.user,
        }),
    },
    
    watch: {
        modelValue (value) {
            if (value) {
                document.body.classList.add('modal-open');
            } else {
                document.body.classList.remove('modal-open');
            }
        },
    },

    methods:{
        closeModal () {
            const vm = this;

            vm.$emit('update:modelValue', false);
        },

        handleHabitsClick(CalendarDay){
            const vm = this;

            vm.dayDrawer = true;
            vm.selectedDate = CalendarDay.ariaLabel;
            console.log(CalendarDay);
        },

        scrollFunc (e) {
            e.preventDefault();
            e.currentTarget.scrollLeft += e.deltaY;
        },

        handleWeekDialog (wk) {
            const vm = this;

            vm.weekSr = wk;
            vm.WeekDialog = true;
        },
    }
}
</script>

<style scoped>
.stat_container {
    max-width: 1000px;
    padding: 50px 20px 100px 20px;
    margin: 0 auto;
}

.section_header .optionDrops {
    font-size: 13px;
    line-height: 18px;
    font-weight: 400;
    color: #5a5a5a;
    text-align: left;
    padding: 7px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    cursor: pointer;
}

.section_header .optionDrops i {
    margin-left: 10px;
    color: #7a7a7a;
    pointer-events: none;
}

.section_header .optionDrops .dropdown_wpr {
    min-width: 160px;
    left: auto;
    right: 1px;
    overflow: hidden;
}

.section_header .optionDrops ul li {
    flex: 1 1 auto;
    border: 0;
    background: transparent;
}

.portal_forms {
    margin-bottom: 50px;
}

.inner_pages .actions .filter_btn {
    padding: 0;
    display: flex;
    gap: 30px;
}

.inner_pages .actions>ul>li:last-child {
    margin-left: auto;
    padding: 7px 0;
}

.inner_pages .actions .fitbit {
    padding: 0;
}

.inner_pages .actions .filter_btn .btn_item {
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 400;
    font-size: 15px;
    line-height: 20px;
    color: #5a5a5a;
    position: relative;
    transition: all 0.3s ease-in-out;
}

.inner_pages .actions .filter_btn .btn_item.active {
    color: #121525;
    font-weight: 500;
}

.empty_section {
    /* border: 1px solid #D9D9D9; */
    border-radius: 10px;
    height: 300px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 10px 15px;
    margin-bottom: 80px;
}

.empty_section h1 {
    font-family: 'Inter', sans-serif;
    font-size: 15px;
    line-height: 25px;
    font-weight: 400;
    color: #757575;
    font-style: italic;
    margin-bottom: 20px;
}

.empty_section button {
    width: 150px;
    height: 40px;
    border-radius: 5px;
    text-transform: uppercase;
    font-size: 13px;
    letter-spacing: 0.5px;
    font-weight: 400;
    color: #fff;
    background: #2f7eed;
}

.habit_calendar {
    display: flex;
    flex-wrap: wrap;
    max-width: 1000px;
    margin: 20px auto 40px auto;
    position: relative;
    background: #fff;
    border-radius: 10px;
    padding-top: 30px;
}

.vc-container {
    min-width: 80%;
    padding: 0 20px;
    background: transparent;
}

:deep(.habit_calendar .vc-container .vc-day-content) {
    background: #efefef;
}

:deep(.habit_calendar .vc-container .vc-day .assign_day) {
    background: #939393;
}

:deep(.habit_calendar .vc-container .vc-day .vc-highlight) {
    background-color: #939393 !important;
}

.habit_calendar .streaks {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding: 30px 0 10px 0;
    width: 20%;
}

.habit_calendar .streaks h4 {
    font-size: 15px;
    line-height: 20px;
    color: #5a5a5a;
    font-weight: 400;
    margin: 0 0 10px;
    text-align: center;
}

.habit_calendar .streaks ul {
    flex: 0 1 335px;
    margin: 52px 0 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    position: relative;
    padding: 10px 30px;
    border-left: 1px solid #e5e5e5;
}

.habit_calendar .streaks ul:before {
    content: '';
    position: absolute;
    left: 50%;
    top: 10px;
    bottom: 10px;
    border-left: 1px solid #d9d9d9;
    z-index: 0;
}

.habit_calendar .streaks li {
    width: 35px;
    height: 35px;
    border-radius: 50%;
    background: #fff;
    /* color: var(--var-element-bg-color); */
    color: #d9d9d9;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #d9d9d9;
    font-size: 14px;
    position: relative;
}

.habit_calendar .streaks li i {
    filter: grayscale(1);
    opacity: 0.5;
}

.habit_calendar .streaks li.winner {
    border: 1px solid #2f7eed;
}

.habit_calendar .streaks li.winner i {
    filter: grayscale(0);
    opacity: 1;
}

.task_details .category_wpr h3.sub_header {
    margin: 0 0 10px;
    font-size: 13px !important;
    line-height: 17px !important;
    font-weight: 500;
    color: #121525;
}

.task_details .perform_activity {
    display: flex;
    flex-direction: column;
    margin-top: 10px;
}

.task_details .task_info {
    margin-bottom: 25px;
}

.task_details .task_info h4 {
    /* color: var(--var-element-text-color); */
    color: #121525;
    padding: 15px 15px 10px 15px;
}

.task_details .task_header .stat {
    /* color: var(--var-element-bg-color); */
    color: #121525;
}

.task_details .task_info h4 label {
    width: 18px;
    height: 18px;
    border-radius: 50%;
    /* border: 1px solid var(--var-element-bg-color);
        color: var(--var-element-bg-color); */
    border: 1px solid #5a5a5a;
    color: #5a5a5a;
    font-size: 9px;
    line-height: 18px;
    font-weight: 500;
    display: flex;
    justify-content: center;
    flex-shrink: 0;
    margin: 2px 12px 0 0;
}

.task_details .task_info h4 i {
    /* color: var(--var-element-bg-color); */
    color: #5a5a5a;
}

.task_details .task_info .details {
    padding-bottom: 10px;
}

.task_details .task_info .details p {
    padding: 0 0 5px 30px;
}

.task_details .success_goal {
    display: flex;
    align-items: center;
    border-radius: 20px;
    font-size: 12px;
    line-height: 16px;
    font-weight: 400;
    color: #121525;
    width: 100%;
    padding: 10px 0 25px 30px;
}

.task_details .success_goal i {
    /* color: var(--var-element-bg-color); */
    color: #5a5a5a;
    font-size: 12px;
    padding-right: 10px;
}

.task_details .achieved_goal {
    font-size: 12px;
    line-height: 14px;
    font-weight: 400;
    border: 1px solid #d4d4d4;
    background: #fbfbfb;
    color: #121525;
    max-width: 150px;
    margin: 10px auto 20px auto;
    padding: 10px 20px;
    border-radius: 20px;
    text-align: center;
    display: block;
}

.task_details .achieved_goal i {
    /* color: var(--var-element-bg-color); */
    color: #5a5a5a;
    padding-right: 5px;
}

.days_wpr {
    border-top: 1px dashed #E4E7EC;
    padding: 20px 0 0;
    width: 100%;
    text-align: center;
}

.task_details ul.days {
    display: flex;
    justify-content: center;
    padding: 7px 0 20px 0;
    gap: 4px;
}

.task_details .days li label .item_wpr {
    width: 35px;
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
    margin: 0;
    padding: 8px 6px;
    background: transparent;
    border: 1px solid transparent;
    border-radius: 3px;
    transition: all 0.3s ease-in-out;
}

.task_details .days li label span {
    font-size: 11px;
    line-height: 14px;
    color: #5a5a5a;
    font-weight: 400;
    padding: 0;
}

.task_details .days li label .tick_box {
    width: 14px;
    height: 14px;
    border-radius: 50%;
    border: 1px solid #cdcdcd;
    position: relative;
    margin-top: 7px;
}

.task_details .days li label .tick_box:after {
    content: '';
    position: absolute;
    left: 2px;
    top: 2px;
    right: 2px;
    bottom: 2px;
    /* background-color: var(--var-element-bg-color); */
    color: #5a5a5a;
    border-radius: 50%;
    transform: scale(0);
    transition: all 0.3s ease-in-out;
}

.task_details .days li label input[type=checkbox]:checked~.item_wpr {
    background: #fbfbfb;
    border: 1px solid #d4d4d4;
}

.task_details .days li label input[type=checkbox]:checked~.item_wpr .tick_box:after {
    transform: scale(1);
}

.activity_wpr {
    padding: 5px 0 0 30px;
    width: 100%;
}

.activity_wpr_center {
    padding: 5px 0 0 0;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.grp_wpr {
    display: flex;
    gap: 8px;
    padding: 10px 0 25px 0;
}

.grp_wpr label .box_item {
    font-size: 13px;
    line-height: 15px;
    border-radius: 4px;
    padding: 10px 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    opacity: 0.5;
    transition: all 0.3s ease-in-out;
}

.grp_wpr label .box_item i {
    font-size: 15px;
}

.grp_wpr label.yes .box_item {
    border: 1px solid #b2eabf;
    background: #effff2;
    color: #23993e;
}

.grp_wpr label.no .box_item {
    border: 1px solid #ffb4b4;
    background: #ffecec;
    color: #eb1414;
}

.grp_wpr label.active .box_item,
.grp_wpr label input[type="radio"]:checked~.box_item {
    opacity: 1;
}

.task_details .quest_panel {
    padding: 25px 15px 0 15px;
    background: #fafafb;
}

.upload_image :deep(.upload_wpr .upload_area) {
    width: 100%;
    min-height: 140px;
    padding: 0;
    border-color: #d4d4d4;
    background-color: #fbfbfb;
}

.upload_image :deep(.upload_wpr .upload_area img:not(.uploaded_img)) {
    max-width: 50px;
}

.upload_image :deep(.upload_wpr .upload_area h4) {
    font-size: 11px;
    line-height: 15px;
    padding: 0;
}

.upload_image :deep(.tab_content) {
    height: 100%;
}
.tracking_list{
    margin-bottom: 30px;
}
.tracking_list li h4 {
    font-size: 13px;
    line-height: 16px;
    font-weight: 400;
    color: #32373b;
}

.tracking_list li h4 span {
    font-size: 18px;
    line-height: 23px;
    display: block;
    color: #121525;
    padding-top: 8px;
}

.report_card {
    border-radius: 6px;
    margin-bottom: 40px;
    background: #fff;
}

.report_card .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px 30px;
}

.report_card .user_info {
    display: flex;
    text-align: left;
    align-items: center;
}

.report_card .user_info img {
    width: 45px;
    height: 45px;
    border-radius: 50%;
    margin-right: 15px;
    border: 1px solid #ccc;
}

.report_card .header h4 {
    font-size: 13px;
    line-height: 16px;
    font-weight: 500;
    color: #32373b;
    margin-bottom: 5px;
}

.report_card .header h3 {
    font-size: 15px;
    line-height: 20px;
    font-weight: 300;
    color: #32373b;
    margin-bottom: 0;
}

.habit_wpr {
    border: 1px solid #eaeaea;
    border-radius: 6px;
    text-align: left;
}

.habit_wpr .title {
    display: flex;
    align-items: center;
    padding: 15px;
    font-size: 14px;
    line-height: 17px;
    font-weight: 500;
    color: #121525;
    background: #fff;
    border-bottom: 1px solid #eaeaea;
    border-radius: 6px 6px 0 0;
    justify-content: space-between;
}

.habit_wpr .title .right {
    display: flex;
    align-items: center;
    color: #9b9b9b;
}

.habit_wpr .title .right button {
    position: relative;
}

.habit_wpr .quick_info {
    border-radius: 5px;
    border: 1px solid #eaeaea;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
    background: #fff;
    width: 200px;
    text-align: left;
    position: absolute;
    right: 100%;
    top: -5px;
}

.habit_wpr .quick_info .info_title {
    font-size: 11px;
    line-height: 13px;
    color: #121525;
    font-weight: 500;
    border-bottom: 1px solid #e9e9e9;
    padding: 5px 10px;
}

.habit_wpr .quick_info p {
    font-size: 9px;
    line-height: 11px;
    color: #5a5a5a;
    font-weight: 500;
    padding: 7px 10px;
}

.habit_wpr .habit_info {
    padding: 20px;
    background: #fff;
}

.habit_wpr .habit_info ul {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -20px;
}

.habit_wpr .habit_info ul li {
    display: flex;
    align-items: center;
    padding: 0 20px;
    width: auto;
}

.habit_wpr .habit_info h6 {
    font-size: 13px;
    line-height: 20px;
    font-weight: 400;
    margin-left: 10px;
}

.habit_wpr .habit_info .ratios {
    font-size: 10px;
    color: #919191;
}

.details_dialog .modal_container {
    padding: 0;
    display: block;
    border-radius: 6px;
}

.details_dialog .modal_header {
    padding: 15px 30px;
    margin: 0;
    flex-direction: row;
}

.details_dialog .modal_header .close_btn {
    right: -5px;
    top: -5px;
    background: #888;
}

.details_dialog .modal_body {
    padding: 20px 30px;
    max-height: 500px;
}

.category_title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 15px;
    position: relative;
}

.category_title h2 {
    font-size: 20px;
    line-height: 25px;
    font-weight: 500;
    color: #121525;
}

.section_header button,
.category_title button {
    font-size: 13px;
    line-height: 16px;
    font-weight: 400;
    padding: 7px 15px;
    border-radius: 5px;
    border: 1px solid #d9d9d9;
    background: #f5f5f5;
    color: #2c3e50;
}

.result_wpr.new {
    overflow: inherit;
    /* margin-top: 12px; */
}

.prog_wpr {
    margin-bottom: 40px;
}

.prog_wpr table {
    min-width: 500px;
}

.progress_tbl .result_wpr table td:not(:last-child) {
    width: 22%;
}

.result_wpr {
    border-color: #eaeaea;
}

.result_wpr table td:first-child,
.result_wpr table th:first-child {
    width: auto;
    padding: 20px;
    text-align: left;
}

.result_wpr table th {
    background: #f5f5f5;
    font-weight: 500;
}

.result_wpr table th:first-child {
    border-top-left-radius: 10px;
}

.result_wpr table th:last-child {
    border-top-right-radius: 10px;
    text-align: right;
}

.result_wpr table td {
    font-weight: 400;
}

.result_wpr table td h6 {
    font-size: 11px;
    line-height: 15px;
    color: #2f7eed;
    font-weight: 500;
    text-align: right;
}

.result_wpr table td .status {
    height: 4px;
    border-radius: 2px;
    background: #eaeaea;
    position: relative;
}

.result_wpr table td .status span {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    border-radius: 2px;
    background: #2f7eed;
}

.result_wpr table td:last-child {
    display: flex;
    justify-content: flex-end;
}

.result_wpr table td button {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    font-size: 11px;
    background: #e9e9e9;
    color: #2c3e50;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.result_wpr table td button+button {
    margin-left: 10px;
}

.score_circle .inner_circle {
    background: #fff;
}

.result_wpr.new .actions {
    margin-bottom: 15px;
}

.result_wpr.new .actions .instruction {
    left: auto;
    right: 0;
    bottom: auto;
    top: 10px;
    font-size: 14px;
    line-height: 18px;
}

.result_wpr.new .actions .instruction img {
    top: auto;
    bottom: 0;
    right: auto;
    left: 90%;
    margin-right: 0;
    margin-left: 10px;
    transform: rotate(45deg);
}

h3.category_title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 13px;
    line-height: 18px;
    font-weight: 500;
    font-family: 'Inter', sans-serif;
    color: #121525;
    margin: 0 0 15px;
}

.stat_list {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -10px;
}

.stat_list li {
    width: 25%;
    padding: 0 10px 20px 10px;
}

.stat_list .stat_card {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #fff;
    border-radius: 10px;
    padding: 15px;
    gap: 10px;
    border: 1px solid #f5f5f5;
}

.stat_list .stat_card .title h5 {
    font-size: 11px;
    line-height: 15px;
    color: #5a5a5a;
    font-weight: 400;
    margin-bottom: 10px;
}

.stat_list .stat_card .title h3 {
    font-size: 16px;
    line-height: 20px;
    color: #121525;
    font-weight: 400;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
    margin-bottom: 5px;
}

.stat_list .stat_card .title h3 i {
    font-size: 13px;
    color: #2f7eed;
}

.stat_list .stat_card .title h3 .field_wpr {
    width: 85px;
}

.stat_list .stat_card .title h3 .field_wpr .multiselect {
    height: 20px;
    min-height: 20px;
    border: 0;
}

.stat_list .stat_card .title h3 .field_wpr .multiselect :deep(.multiselect-caret) {
    margin-right: 5px;
    height: 11px;
}

.stat_list .stat_card .title h3 .field_wpr .multiselect :deep(.multiselect-clear) {
    display: none;
}

.stat_list .stat_card .title h3 .field_wpr .multiselect :deep(.multiselect-multiple-label),
.stat_list .stat_card .title h3 .field_wpr .multiselect :deep(.multiselect-placeholder),
.stat_list .stat_card .title h3 .field_wpr .multiselect :deep(.multiselect-single-label) {
    padding: 0 5px;
    font-size: 9px;
    line-height: 10px;
}

.stat_list .stat_card .title h3 .field_wpr .multiselect :deep(.multiselect-dropdown ul.multiselect-options li) {
    font-size: 9px;
    line-height: 10px;
    padding: 5px;
}

.overview .rate {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.overview .rate .avr_score {
    font-size: 11px;
    line-height: 15px;
    font-weight: 500;
    color: #2f7eed;
    padding: 2px 7px;
    border: 1px solid #2f7eed;
    border-radius: 10px;
}

.overview .rate h6 {
    font-size: 9px;
    line-height: 10px;
    color: #5a5a5a;
    font-weight: 500;
    margin-top: 5px;
    max-width: 100px;
    text-align: center;
    /* display: none; */
}

.overview .score_circle .inner_circle .score {
    font-size: 9px;
    line-height: 11px;
    font-weight: 500;
}

.overview .score_circle .inner_circle .score span {
    font-size: 8px;
}

.fitbit_connector {
    display: flex;
    justify-content: flex-end;
    /* margin-bottom: 20px; */
}

.fitbit_connector .capsule_btn div {
    flex: 0 0 30px;
    width: 30px;
    height: 16px;
}

.fitbit_connector .capsule_btn div span {
    position: absolute;
    left: 2px;
    top: 2px;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background: #F2A31D;
    transition: all 0.3s ease-in-out;
    margin: 0;
}

.fitbit_connector .switch_option h5 {
    font-size: 13px;
    line-height: 16px;
}

.widgets_wpr span.chart_title {
    left: 0;
}

.content_card_wpr {
    border: 1px solid #f5f5f5;
    border-radius: 8px;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.03);
    background: #fff;
    margin-bottom: 30px;
    overflow: hidden;
}

.content_card_wpr .title {
    font-size: 18px;
    line-height: 25px;
    color: #121525;
    font-weight: 400;
    padding: 15px 30px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #e9e9e9;
}

.content_card_wpr .title span {
    font-size: 11px;
    line-height: 18px;
    font-weight: 400;
    color: #777;
    margin-left: auto;
}

.content_card_wpr .title h4 {
    font-size: 16px;
    line-height: 25px;
    color: #121525;
    font-weight: 400;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
}

.content_card_wpr .title h4 i {
    font-size: 14px;
    color: #2f7eed;
}

.content_card_wpr .title h4 label {
    font-size: 11px;
    line-height: 20px;
    border-radius: 50%;
    color: #2f7eed;
    background: #e3eefd;
    font-weight: 500;
    display: inline-block;
    padding: 0 6px;
}

.content_card_wpr .title .rate {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-left: auto;
}

.content_card_wpr .title .rate .score {
    font-size: 9px;
    line-height: 11px;
    font-weight: 500;
}

.content_card_wpr .title .rate .score span {
    font-size: 8px;
}

/* .content_card_wpr .title .rate .avr_score{
    font-size: 11px;
    line-height: 15px;
    font-weight: 500;
    color: #2f7eed;
    padding: 2px 7px;
    border: 1px solid #2f7eed;
    border-radius: 10px;
} */

.content_card_wpr .title .rate h6 {
    font-size: 9px;
    line-height: 10px;
    color: #5a5a5a;
    font-weight: 400;
    margin-top: 5px;
    max-width: 100px;
    text-align: center;
    display: none;
}

.content_card_wpr .title .toggle_btn {
    width: 20px;
    height: 20px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    background: #eee;
    font-size: 10px;
    color: #5a5a5a;
    margin-left: 15px;
    transition: all 0.5s ease-in-out;
}

.content_card_wpr.show .title .toggle_btn {
    transform: rotate(-180deg);
}

.content_card_wpr .section_content {
    max-height: 55px;
    transition: all 0.5s ease-in-out;
}

.content_card_wpr.show .section_content {
    max-height: 1000vh;
    overflow: overlay;
}

.content_card_wpr.show .section_content::-webkit-scrollbar {
    width: 3px;
}

.content_card_wpr.show .section_content::-webkit-scrollbar-thumb {
    background: #dbdbdb;
    border-radius: 2px;
}

.content_card_wpr.show .section_content {
    max-height: 1000vh;
    overflow: overlay;
}

.section_content .list li {
    display: flex;
    padding: 0 30px;
    min-height: 55px;
    border-bottom: 1px solid #f5f5f5;
    display: flex;
    align-items: center;
    font-size: 13px;
    line-height: 18px;
    font-weight: 400;
    color: #5a5a5a;
}

.section_content .list li .trends {
    margin-left: 10px;
    background: transparent;
}

.section_content .list li .amt {
    font-weight: 500;
    color: #121525;
    display: flex;
    gap: 10px;
    margin-left: auto;
}

.section_content .list li .soon {
    font-weight: 400;
    color: #999;
    margin-left: auto;
}

.workout_card li .info_wpr {
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    padding: 0 0 15px 0;
    margin: 0;
    border: 0;
    box-shadow: none;
    gap: 0;
    transition: all 0.3s ease-in-out;
}

.workout_card li.show .info_wpr {
    gap: 15px;
}

.workout_card li.show .info_wpr h4 i {
    transform: rotate(-180deg);
}

.workout_card li a {
    font-size: 11px;
    line-height: 14px;
    font-weight: 500;
    color: #2f7eed;
    margin: 0 0 0 auto;
    cursor: pointer;
}

.workout_card li.show .info_wpr .details {
    max-height: 1000px;
}

.workout_card li:last-child .info_wpr {
    border-bottom: 0;
}

.workout_card li .card_wrapper {
    border-radius: 6px;
    display: flex;
    gap: 15px;
    cursor: pointer;
    padding: 15px 0 0 0;
    width: 100%;
}

.workout_card li .card_wrapper svg {
    flex: 0 0 20px;
    height: 20px;
}

.workout_card li .info_wpr h4 {
    font-size: 13px;
    line-height: 18px;
    color: #5a5a5a;
    font-weight: 400;
    display: flex;
    align-items: center;
    cursor: pointer;
}

.workout_card li .info_wpr h4 .date {
    font-weight: 500;
    color: #121525;
    padding-right: 10px;
}

.workout_card li .info_wpr span.tag {
    background: #ecf4ff;
    padding: 0 6px;
    border-radius: 8px;
    font-size: 9px;
    line-height: 15px;
    color: #2f7eed;
    margin-left: 6px;
}

.workout_card li .info_wpr h4 i {
    font-size: 10px;
    margin-left: auto;
    color: #999;
    transition: all 0.3s ease-in-out;
    display: none;
}

.content_card_wpr.show .workout_card li .info_wpr h4 i {
    display: block;
}

.workout_card li .info_wpr h6 {
    font-size: 11px;
    line-height: 15px;
    color: #5a5a5a;
    font-weight: 500;
    display: flex;
    flex-wrap: wrap;
    /* margin: 0 -5px 5px -5px; */
    gap: 5px;
}

.workout_card li .info_wpr h6 span {
    padding: 0 5px 0 0;
}

.workout_card li .info_wpr h6 span:not(:last-child) {
    border-right: 1px solid #d9d9d9;
}

.workout_card li .info_wpr .blocks {
    display: flex;
    flex-direction: column;
    gap: 15px;
    padding-bottom: 10px;
    flex: 1 1 auto;
}

.workout_card li .info_wpr .blocks .block_title {
    font-size: 13px;
    line-height: 16px;
    color: #121525;
    font-weight: 500;
}

.workout_card li .info_wpr .blocks .block_title h6 {
    font-weight: 400;
    margin-top: 5px;
}

.workout_card li .info_wpr .block_item {
    border: 1px solid #f5f5f5;
    background: #fff;
    border-radius: 5px;
    border-radius: 10px;
    display: flex;
    gap: 12px;
}

.workout_card li .info_wpr .block_item h5 {
    font-size: 11px;
    line-height: 16px;
    color: #121525;
    font-weight: 500;
}

.workout_card li .info_wpr .block_item h6 {
    font-size: 9px;
    line-height: 12px;
    color: #5a5a5a;
    font-weight: 400;
}

.workout_card li .info_wpr .block_item h6 .v_tag {
    width: 14px;
    height: 14px;
    background: #999;
    border: 1px solid #e9e9e9;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 8px;
    line-height: 10px;
    font-weight: 500;
    color: #fff;
    margin-right: 5px;
    border-radius: 50%;
    padding: 0;
}

table.task_history {
    width: 100%;
    border-spacing: 8px;
    background: #f5f5f5;
    border-radius: 5px;
    padding: 0 5px 5px 5px;
}

.task_history th,
.task_history td {
    padding: 10px 0;
    border-radius: 3px;
    text-align: center;
    font-size: 11px;
    line-height: 14px;
    font-weight: 400;
    color: #121525;
}

.task_history td {
    width: 23%;
    background: #fff;
    color: #777;
}


.workout_card li .info_wpr .details {
    display: flex;
    flex-wrap: wrap;
    max-height: 0;
    overflow: hidden;
    gap: 15px;
    transition: all 0.3s ease-in-out;
}

.workout_card li .info_wpr .details .rest_info {
    font-size: 11px;
    line-height: 18px;
    font-weight: 400;
    color: #5a5a5a;
}

.workout_card li .info_wpr .details .redirect_btn {
    font-size: 11px;
    line-height: 14px;
    padding: 6px 12px;
    border-radius: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
    cursor: pointer;
    margin: 0 0 0 auto;
    text-decoration: none;
}

.workout_card li .video_area {
    width: 25%;
    border-right: 1px solid #f5f5f5;
}

.workout_card li .video_area img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
}

.workout_card li .info_wpr .details .details_info {
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
    justify-content: center;
    gap: 7px;
}

.workout_card li.show .info_wpr .details {
    max-height: 1000px;
}

/* .overview{
    display: flex;
    gap: 40px;
} */
.overview .quick_status {
    display: flex;
    gap: 30px;
    margin-bottom: 30px;
}

.overview .quick_status .quick_card {
    background: #fff;
    border-radius: 8px;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.03);
    width: calc(33.333% - 20px);
    display: flex;
    flex-direction: column;
}

.overview .quick_status .quick_card .title {
    font-size: 16px;
    line-height: 22px;
    color: #121525;
    font-weight: 400;
    padding: 10px 20px;
    border-bottom: 1px solid #f5f5f5;
}

.overview .quick_status .quick_card .card_info {
    padding: 15px 20px;
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
}

.quick_card .card_info ul {
    display: flex;
    flex-direction: column;
    gap: 15px;
    margin-bottom: 10px;
}

.quick_card .card_info ul li {
    display: flex;
    gap: 15px;
    font-size: 11px;
    line-height: 18px;
    color: #777;
    align-items: center;
}

.quick_card .card_info ul li .tag {
    background: #ecf4ff;
    padding: 0 6px;
    border-radius: 8px;
    font-size: 9px;
    line-height: 15px;
    color: #2f7eed;
    font-weight: 500;
    margin-left: auto;
}

.quick_card .card_info ul li .item_details {
    font-size: 14px;
    line-height: 20px;
    color: #999;
    display: flex;
    flex-direction: column;
    gap: 5px;
    width: calc(33.333% - 10px);
    padding-bottom: 10px;
}

.quick_card .card_info ul li .item_details label {
    font-size: 11px;
    line-height: 16px;
    font-weight: 500;
    color: #121525;
}

.quick_card .card_info ul li .rate {
    width: 40px;
    flex-shrink: 0;
    margin-left: auto;
}

.quick_card .card_info .actions {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 0;
    margin-top: auto;
}

.quick_card .card_info .actions a {
    font-size: 11px;
    line-height: 14px;
    padding: 5px 0;
    color: #2f7eed;
    font-weight: 500;
    cursor: pointer;
    text-decoration: none;
    display: flex;
    align-items: center;
    gap: 5px;
}

.quick_card .card_info .actions button {
    font-size: 11px;
    line-height: 14px;
    padding: 4px 10px;
    border-radius: 12px;
    border: 1px solid #b4d3ff;
    color: #2f7eed;
    font-weight: 500;
    cursor: pointer;
    text-decoration: none;
    display: flex;
    align-items: center;
    gap: 5px;
}

.quick_card .card_info .plan_name {
    font-size: 13px;
    line-height: 20px;
    color: #121525;
    font-weight: 500;
    cursor: pointer;
    text-decoration: none;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
    padding-bottom: 10px;
}

.quick_card .picture_section {
    display: flex;
    border-radius: 0 0 8px 8px;
    overflow: hidden;
}

.quick_card .picture_section li {
    width: 50%;
    display: flex;
    flex-direction: column;
}

.quick_card .picture_section li:not(:first-child) {
    border-left: 1px solid #f5f5f5;
}

.quick_card .picture_section li .section_title {
    font-size: 11px;
    line-height: 14px;
    font-weight: 400;
    color: #121525;
    padding: 7px 15px;
    display: none;
}

.quick_card .picture_section li .img_viewer {
    flex: 1 1 auto;
}

.quick_card .picture_section li .img_viewer img {
    height: 100%;
    width: 100%;
    object-fit: cover;
}

.overview .left_panel {
    flex: 1 1 auto;
}

.overview .right_panel {
    width: 400px;
    flex-shrink: 0;
}

.overview .widgets_wpr {
    padding: 20px 30px;
    border-radius: 8px;
    height: auto;
    margin-bottom: 30px;
}

.overview .widgets_wpr .header h2 {
    font-size: 22px;
    line-height: 30px;
}

.overview .widgets_wpr .header .lb_tabs {
    display: flex;
    gap: 5px;
    margin: auto 0;
}

.overview .widgets_wpr .header .lb_tabs button {
    background: transparent;
    height: 27px;
    padding: 5px 10px;
    color: #121525;
    font-size: 11px;
    line-height: 1;
    border-radius: 3px;
    border: 1px solid #dbdbdb;
    outline: 0;
    text-transform: capitalize;
}

.overview .widgets_wpr .header .lb_tabs button.active {
    background: #dbdbdb;
}

.overview .right_panel .widgets_wpr span.chart_title {
    font-size: 11px;
    letter-spacing: 0.7px;
}

.overview .right_panel .widgets_wpr ul li {
    width: 125px;
    height: 28px;
}

.overview .right_panel .widgets_wpr ul li select {
    font-size: 11px;
    padding: 0 25px 0 10px;
}

.overview .right_panel .stat_list {
    margin: 0;
    padding: 10px 30px;
    background: #fff;
    border-radius: 8px;
}

.overview .right_panel .stat_list li {
    width: 100%;
    padding: 0;
}

.overview .right_panel .stat_list li:not(:first-child) {
    border-top: 1px solid #f5f5f5;
}

.overview .right_panel .stat_list li .stat_card {
    border: 0;
    border-radius: 0;
    padding: 20px 0;
}

.overview .right_panel .stat_list .stat_card .rate {
    width: 60px;
}

/* Drawer */
.habit_drawer .task_details .category_wpr {
    background: transparent;
    padding: 0;
    border: 0;
}

.habit_drawer .task_details .task_info {
    background: #fff;
}

.task_details .task_info .sample_type {
    font-size: 13px;
    line-height: 18px;
    font-weight: 500;
    color: #121525;
    background: #f5f5f5;
    border-bottom: 1px solid #e9e9e9;
    padding: 10px 15px;
}

.habit_drawer h3.category_title {
    font-size: 15px;
    line-height: 20px;
    font-weight: 500;
}

.habit_drawer .task_details .task_info .details p {
    font-size: 12px;
    line-height: 18px;
    color: #656565;
    font-weight: 300;
    padding-bottom: 0;
}

.habit_drawer .task_details .perform_activity {
    margin-top: 5px;
}

.habit_drawer .task_details .task_info .details span {
    font-weight: 500;
}

.habit_drawer .grp_wpr label .box_item {
    font-size: 11px;
    line-height: 15px;
    border-radius: 3px;
    padding: 5px 10px;
    opacity: 1;
    gap: 7px;
}

.habit_drawer .grp_wpr label .box_item i {
    font-size: 10px;
}

.instruction {
    position: absolute;
    left: 40%;
    top: 15px;
    transform: translateX(-50%);
    /* font-family: 'Architects Daughter', cursive; */
    font-size: 13px;
    line-height: 20px;
    font-style: italic;
    color: #5a5a5a;
    /* width: 150px; */
}

.instruction img {
    position: absolute;
    top: 50%;
    right: 100%;
    margin-right: 10px;
    width: 50px;
    transform: scaleX(-1) rotate(90deg);
}

.section_header .instruction {
    top: 25px;
    left: auto;
    right: 0;
    margin-bottom: 50px;
    z-index: 1;
}

.section_header .instruction img {
    width: 45px;
    right: auto;
    left: 100%;
    margin-right: 0;
    margin-left: 10px;
    transform: rotate(90deg);
}

/* .habit_drawer .upload_image{
    padding-left: 30px;
} */
@media(max-width: 1350px) {
    .stat_list li {
        width: 33.333%;
    }
}

@media(max-width: 1199px) {
    .result_wpr.new .actions>ul {
        height: 51px;
    }
}

@media(min-width:992px) and (max-width: 1099px) {
    .stat_list li {
        width: 50%;
    }
}

@media(max-width: 850px) {
    .stat_list li {
        width: 50%;
    }
}

@media(max-width: 767px) {
    .inner_pages .actions.filter_bar .filter_btn .btn_item {
        font-size: 13px;
        padding: 0 20px;
    }

    .report_card .header {
        padding: 20px;
    }

    .portal_forms .report_card .user_info img {
        height: 35px;
        width: 35px;
    }

    .portal_forms .report_card .header h4 {
        font-size: 11px;
    }

    .portal_forms .report_card .header h3 {
        font-size: 13px !important;
        line-height: 17px !important;
        display: block;
    }

    .prog_wpr table th,
    .prog_wpr table td {
        font-size: 11px;
        line-height: 15px;
        padding: 15px 10px !important;
    }

    .prog_wpr table td:not(:last-child) {
        width: 22%;
    }

    .prog_wpr table .score_circle {
        transform: scale(0.8);
    }
}

@media(max-width: 499px) {
    .inner_pages .actions.filter_bar .filter_btn .btn_item {
        font-size: 11px;
        line-height: 14px;
        padding: 0 10px;
    }

    .report_card {
        margin-bottom: 40px;
    }

    .inner_pages .actions .filter_btn {
        gap: 20px;
    }

    .inner_pages .actions .filter_btn .btn_item {
        font-size: 13px;
        line-height: 18px;
    }

    .portal_forms .report_card .header h3 span {
        display: block;
        font-size: 10px;
        line-height: 15px;
    }

    .stat_list li {
        width: 100%;
    }

    .empty_section h1 {
        font-size: 13px;
        line-height: 23px;
    }

    .empty_section button {
        width: 100px;
        height: 30px;
        border-radius: 3px;
        font-size: 11px;
        letter-spacing: 0.3px;
    }
}

@media(max-width: 450px) {
    .result_wpr.new {
        margin: 0 0 10px 0;
    }

    .result_wpr.new .actions {
        margin-bottom: 10px;
    }

    .result_wpr.new .actions>ul li.filter_btn {
        width: 100%;
        justify-content: flex-start;
    }

    .inner_pages .actions ul li:last-child {
        margin-left: 0;
        margin-right: auto;
    }

    .inner_pages .actions ul li:last-child .dropdown_wpr {
        right: auto;
        left: 1px;
    }

    .result_wpr.new .actions>ul li.search_area {
        width: inherit;
        order: -1;
    }

    .result_wpr.new .actions>ul li.search_area input[type=text] {
        width: 0;
    }

    .result_wpr.new .actions>ul li.search_area.active input[type=text] {
        width: 300px;
    }
}
</style>